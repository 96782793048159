<template>
  <CommonTeleportModal @cancel="close" v-show="isShow">
    <div class="activity-social-modal">
      <img
        @click="close"
        class="activity-social-modal-close1"
        src="https://qncweb.ktvsky.com/20241031/vadd/6d30587fbb1dfd1e3b0c89cf44d18047.png"
      />

      <div @click="sub" class="activity-social-modal-close"></div>
    </div>
  </CommonTeleportModal>
</template>
<script>
import CommonTeleportModal from './../common/index.vue'

export default {
  name: 'ActivitySocialModal',
  components: {
    CommonTeleportModal,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    // const { isShow } = toRefs(props)

    // const show = () => {
    //   isShow.value = true
    // }

    const close = () => {
      // isShow.value = false
      emit('close')
    }

    const sub = () => {
      emit('sub')
    }

    return {
      // isShow,
      // show,
      close,
      sub,
    }
  },
}
</script>
<style lang="stylus" scoped>
.activity-social-modal
  position relative
  width 700px
  height 757px
  // border-radius 20px
  background url('https://qncweb.ktvsky.com/20241031/vadd/1f9b8725d509a81acae8892400425380.png') no-repeat
  background-size 100% 100%
  background-position center
  display flex
  flex-direction column
  align-items center
  &-close
    position absolute
    bottom 48px
    left 50%
    transform translateX(-50%)
    width 360px
    height 80px
  &-close1
    position absolute
    top 30px
    right 30px
    width 32px
    height 32px
  @media screen and (max-width: 1200px)
    zoom 0.8
</style>
